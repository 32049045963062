import { Vehicle } from "@/interfaces/vehicle";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { vehiclesService } from "@/services/vehicles.service";
import { RootState } from "@/vuex/types";
import { ActionTree } from "vuex";
import { VehicleState } from "./vehicle.types";

type VehicleActionTree = ActionTree<VehicleState, RootState>;

export const actions: VehicleActionTree = {
  async loadVehicles(context) {
    context.commit("setLoading", true);
    const payload = await vehiclesService.get();
    const pagination = await vehiclesService.getPagination();
    context.commit("setVehicles", payload);
    context.commit("setPagination", pagination);
    context.commit("setLoading", false);
  },

  async removeVehicle(context, vehicle: Vehicle) {
    context.commit("setLoading", true);
    try {
      EventBus.$emit("unSelectedModalActive");
      const response = await vehiclesService.delete(vehicle);
      if (response) {
        messagesService.renderSuccessMessage(
          i18n.t("vehicles.deleted_successfully").toString()
        );
        context.dispatch("loadVehicles");
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    context.commit("setLoading", false);
  },

  async removeMultipleVehicles(context, vehicles: Vehicle[]) {
    context.commit("setLoading", true);
    try {
      const response = await vehiclesService.deleteMultiple(
        vehicles.map(vehicle => Number(vehicle.id))
      );
      EventBus.$emit("unselectMultipleItems");
      if (response) {
        messagesService.renderSuccessMessage(
          i18n.t("vehicles.deleted_successfully").toString()
        );
        await context.dispatch("loadVehicles");
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    context.commit("setLoading", false);
  }
};

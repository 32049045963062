import { Driver } from "@/interfaces/driver";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { DriverState } from "./driver.type";

type DriversMutationTree = MutationTree<DriverState>;

export const mutations: DriversMutationTree = {
  setLoading(state, value) {
    state.loading = value;
  },
  setPagination(state, pagination: TablePagination) {
    state.pagination = pagination;
  },
  setDrivers(state, drivers: Driver[]) {
    state.drivers = drivers;
  },
  resetDriver(state) {
    state.driver = null;
  },
  setDriver(state, driver) {
    state.driver = driver;
  }
};

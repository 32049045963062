import { Driver } from "@/interfaces/driver";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { DriverState } from "./driver.type";

type DriversGetters = GetterTree<DriverState, RootState>;

export const getters: DriversGetters = {
  loading(store): boolean {
    return store.loading;
  },
  pagination(store) {
    return store.pagination;
  },
  drivers(store): Driver[] | null {
    return store.drivers;
  }
};

import { Vehicle } from "@/interfaces/vehicle";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { VehicleState } from "./vehicle.types";

type VehicleMutationTree = MutationTree<VehicleState>;

export const mutations: VehicleMutationTree = {
  setVehicles(state, vehicles: Vehicle[]) {
    state.vehicles = vehicles;
  },
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state, pagination: TablePagination) {
    state.pagination = pagination;
  }
};

import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./driver.actions";
import { getters } from "./driver.getters";
import { mutations } from "./driver.mutations";
import { state } from "./driver.state";
import { DriverState } from "./driver.type";

const namespaced: boolean = true;
export const DriversModule: Module<DriverState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

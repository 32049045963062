import { Driver } from "@/interfaces/driver";
import { EventBus } from "@/internal";
import { driverService } from "@/services/driver.service";
import { RootState } from "@/vuex/types";
import { ActionTree } from "vuex";
import { DriverState } from "./driver.type";

type DriversActionTree = ActionTree<DriverState, RootState>;

export const actions: DriversActionTree = {
  async setLoading(context, value) {
    context.commit("setLoading", value);
  },
  async loadDrivers(context) {
    context.commit("setLoading", true);
    const response = await driverService.get();
    const pagination = await driverService.getPagination();
    context.commit("setPagination", pagination);
    context.commit("setDrivers", response);
    context.commit("setLoading", false);
  },
  async removeDriver(context, driver: Driver) {
    context.commit("setLoading", true);
    EventBus.$emit("unSelectedModalActive");
    const response = await driverService.delete(driver);
    context.commit("setLoading", false);
    if (response) {
      context.dispatch("loadDrivers");
    }
  },
  async removeMultipleDrivers(context, drivers: Driver[]) {
    context.commit("setLoading", true);
    EventBus.$emit("unselectMultipleItems");
    const response = await driverService.deleteMultiple(drivers.map(
      driver => driver.id
    ) as number[] | undefined[]);

    context.commit("setLoading", false);
    if (response) {
      context.dispatch("loadDrivers");
    }
  }
};

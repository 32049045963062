import { Callback } from "@/types/types";
import { DriversModule } from "@/vuex/modules/inventory/drivers/driver.index";
import { VehicleModule } from "@/vuex/modules/vehicle/vehicle.index";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./Inventory.template.vue";

@Component({
  mixins: [Template]
})
export default class InventoryComponent extends Vue {
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeSubTitle", { namespace: "MegaMenuModule" })
  public changeSubTitleAction!: Callback;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  constructor() {
    super();
    this.changeTitleAction("inventory.title");
    this.changeSubTitleAction("");
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_inventory@2x.png")
    );
  }

  protected created() {
    this.$store.registerModule("VehicleModule", VehicleModule);
    this.$store.registerModule("DriversModule", DriversModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("VehicleModule");
    this.$store.unregisterModule("DriversModule");
  }
}

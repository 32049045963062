import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./vehicle.actions";
import { getters } from "./vehicle.getters";
import { mutations } from "./vehicle.mutations";
import { state } from "./vehicle.state";
import { VehicleState } from "./vehicle.types";

const namespaced: boolean = true;

export const VehicleModule: Module<VehicleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

import { Vehicle } from "@/interfaces/vehicle";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";
import { VehicleState } from "./vehicle.types";

type VehicleGetter = GetterTree<VehicleState, RootState>;

export const getters: VehicleGetter = {
  vehicles(state): Vehicle[] {
    return state.vehicles;
  },
  pagination(state): TablePagination | null {
    return state.pagination;
  },
  loading(state): boolean {
    return state.loading;
  }
};
